import { IEnvironment, PROD_API, PROD_API2, SENTRY_DSN } from './environment.interface';

export const environment: IEnvironment = {
  name: 'production-test',
  production: true,
  api: PROD_API,
  api2: PROD_API2,
  chargifyKey: 'chjs_pm6cdjpqjwmrg7ymxxf3fwh6',
  chargifyHost: 'https://platform500.chargify.com',
  sentryDsn: SENTRY_DSN,
  frontAppChatId: '650412e8d35353baa3ad91db97bbe95f',
};
