export interface IEnvironment {
  name: string;
  production: boolean;
  api: string;
  api2?: string;
  chargifyKey: string;
  chargifyHost: string;
  sentryDsn?: string;
  frontAppChatId?: string;
}

export const SENTRY_DSN = 'https://e5ebde4b74344e2dac68276be37dc6bf@o445185.ingest.sentry.io/5819522';

export const PROD_API = '/master/backend/api/wizard/v1';
export const PROD_API2 = '/master/backend/api/wizard/v2';
