import { ConfigServiceInterface } from './config-service.interface';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BrowserWindowRef } from '../window/window.service';

@Injectable()
export class ConfigService implements ConfigServiceInterface {
  private domain = '';

  constructor(@Inject(BrowserWindowRef) private window: BrowserWindowRef) {
    if (environment.production) {
      const { host } = this.window.nativeWindow.location;
      const hostParsed = host.split('.');
      this.domain = hostParsed.length > 1 ? hostParsed[1] : hostParsed[0];
    } else {
      this.domain = 'stage800';
    }
  }

  get apiUrl(): string {
    return environment.api;
  }

  get apiUrlV2(): string {
    return environment.api2;
  }

  get chargifyKey(): string {
    return environment.chargifyKey;
  }

  get chargifyHost(): string {
    return environment.chargifyHost;
  }

  get defaultRedirectUrl(): string {
    return `https://${this.domain}.com`;
  }

  get homeUrl(): string {
    return `https://${this.domain}.com`;
  }

  get docsUrl(): string {
    return `https://support.${this.domain}.com`;
  }

  get mobileAppsUrl(): string {
    return `https://${this.domain}.com/mobile-applications`;
  }

  get supportUrl(): string {
    return `https://support.${this.domain}.com`;
  }

  get supportEmail(): string {
    return `support@${this.domain}.com`;
  }

  get controlUrl(): string {
    return `https://control.${this.domain}.com`;
  }

  get jwtAuthIframeUrls(): string[] {
    return [];
    // return [
    //   `https://${this.domain}.com/wp-content/themes/main/assets/dist/assets/php/auth.php`,
    //   `https://docs.${this.domain}.com/wp-content/themes/main/assets/dist/assets/php/auth.php`,
    // ];
  }

  get acrossIframeUrl(): string {
    return '';
    // return `https://${this.domain}.com/wp-content/themes/main/assets/dist/assets/php/across.php`;
  }

  get videoUrl(): string {
    return `https://${this.domain}.com/video-education`;
  }

  get frontAppChatId(): string {
    return environment.frontAppChatId;
  }
}
