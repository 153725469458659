export class UserResponse {
  constructor(
    public id: number,
    public fname: string,
    public lname: string,
    public login: string,
    public email: string,
    public company: any,
    public changePassword: boolean,
    public chat_variables: {
      front_app_master_id: string,
      front_app_hash: string
    }

  ) {}

  static fromJson(json: any) {
    return new UserResponse(
      json.data.id,
      json.data.first_name,
      json.data.last_name,
      json.data.login,
      json.data.email,
      json.data.company,
      json.data.change_password,
      json.data.chat_variables,
    );
  }
}
